import { sanitize } from 'dompurify'
import { Descendant, Editor, Text, Transforms } from 'slate'
import {
  CustomElement,
  CustomText,
  LinkElement,
  MentionElement,
} from 'modules/community/types/CustomEditor'

export const insertText = (editor: Editor, nativeEmoji: string) => {
  const text = { text: nativeEmoji }

  Transforms.insertNodes(editor, text)
}

export const serializeHTML = (node: { children: Descendant[] } | Descendant) => {
  if (Text.isText(node)) {
    let string = sanitize(node.text)
    const textNode = node as CustomText
    if (textNode.underline) {
      string = string.trim() ? `<u>${string}</u>` : ''
    }
    if (textNode.italic) {
      string = string.trim() ? `<em>${string}</em>` : ''
    }
    if (textNode.bold) {
      string = string.trim() ? `<strong>${string}</strong>` : ''
    }
    return string
  }

  const children: string = node.children.map((n: Descendant) => serializeHTML(n)).join('')

  const elementNode = node as CustomElement

  switch (elementNode.type) {
    case 'paragraph':
      return children.trim() ? `<p>${children}</p>` : '<br>'
    case 'link': {
      const linkNode = node as LinkElement
      return children.trim() ? `<a href="${linkNode.url}" target="_blank">${children}</a>` : ''
    }
    case 'mention': {
      const mentionNode = node as MentionElement
      return mentionNode.displayName.trim() ? `<@${mentionNode.id}:${mentionNode.displayName}>` : ''
    }

    default:
      return children
  }
}
