import { useSlate } from 'slate-react'
import { twMerge } from 'tailwind-merge'
import LinkIcon from 'shared/icons/link-icon'
import UnlinkIcon from 'shared/icons/unlink-icon'
import { ToolbarButton } from '../../common/toolbar-button'
import { isLinkActive, unwrapLink } from '../../utils/link'

const LinkButton = ({ setLinkIsEditing }: { setLinkIsEditing: (value: boolean) => void }) => {
  const editor = useSlate()
  const linkActive = isLinkActive(editor)

  const handleClick = () => {
    if (linkActive) {
      unwrapLink(editor)
    } else {
      setLinkIsEditing(true)
    }
  }

  return (
    <ToolbarButton
      format={linkActive ? 'unlink' : 'link'}
      active={linkActive}
      onClick={handleClick}
    >
      {linkActive ? (
        <UnlinkIcon
          className={twMerge('h-5 w-5 text-[#8995b0b3]', linkActive && 'text-[initial]')}
        />
      ) : (
        <LinkIcon className={twMerge('h-5 w-5 text-[#8995b0b3]', linkActive && 'text-[initial]')} />
      )}
    </ToolbarButton>
  )
}

export default LinkButton
