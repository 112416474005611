import React from 'react'

function ActivityIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.11035 6.12219V10.3907L12.5252 12.0981" stroke="#98A2B3" />
      <path d="M1 3.98804V6.97601H4.41483" stroke="#98A2B3" />
      <path
        d="M1.42676 6.69423L2.37437 5.74662C3.06734 4.3236 4.14635 3.12401 5.48828 2.28474C6.83022 1.44546 8.38105 1.00028 9.96383 1C11.5294 0.998553 13.0653 1.42764 14.4034 2.24034C15.7415 3.05304 16.8304 4.21806 17.5509 5.60798C18.2715 6.99789 18.5959 8.55918 18.4888 10.1211C18.3817 11.683 17.8472 13.1854 16.9436 14.464C16.0401 15.7425 14.8024 16.748 13.3659 17.3704C11.9294 17.9929 10.3493 18.2083 8.79859 17.9932C7.24786 17.778 5.78615 17.1406 4.57332 16.1506C3.36049 15.1606 2.44325 13.8562 1.92191 12.3799"
        stroke="#98A2B3"
      />
    </svg>
  )
}

export default ActivityIcon
