import React, { useCallback } from 'react'
import { usePopper } from 'react-popper'
import { RenderElementProps } from 'slate-react'
import { useHover } from 'shared/hooks/use-hover'
import { MentionElement } from 'modules/community/types/CustomEditor'
import styles from '../../CommentEditor.module.css'

export interface CustomRenderMentionProps
  extends Partial<Pick<RenderElementProps, 'attributes' | 'children'>> {
  element: MentionElement
}

export const Mention = ({ attributes, children, element }: CustomRenderMentionProps) => {
  const { ref: hoverRef, hovered } = useHover<HTMLSpanElement>()
  const [referenceElement, setReferenceElement] = React.useState<null | HTMLSpanElement>(null)
  const [popperElement, setPopperElement] = React.useState<null | HTMLDivElement>(null)
  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'top-start',
      strategy: 'fixed',
    },
  )

  const setRef = useCallback(
    (node: HTMLSpanElement) => {
      // @ts-ignore
      // NOTE: some weird type inconsistency. This was of type `MutableRef` in the comments repo
      // here though it's somehow just a RefObject
      // while the code is the same
      hoverRef.current = node
      setReferenceElement(node)
    },
    [setReferenceElement],
  )

  return (
    <span ref={setRef} className={styles.CommentMentionWrapper}>
      <span
        {...attributes}
        className={styles.CommentMention}
        contentEditable={false}
        data-cy={`mention-${element.displayName.replace(' ', '-')}`}
        data-avatar-url={element.profileImageUrl || ''}
        data-id={element.id || 0}
      >
        @{element.displayName}
        {children}
      </span>
      {hovered && (
        <div
          ref={setPopperElement}
          style={{ zIndex: 100, ...popperStyles.popper }}
          {...popperAttributes}
          contentEditable={false}
        >
          <div className={styles.CommentMentionPopover}>
            {/* TODO: Delete when this task complete - https://app.shortcut.com/systeme/story/27961/extra-info-in-mentions-in-comments-response */}
            {element.profileImageUrl && (
              <img
                className={styles.CommentMentionPopoverUserAvatar}
                src={element.profileImageUrl}
                width={46}
                height={46}
                alt={element.displayName}
              />
            )}
            <p className={styles.CommentMentionPopoverDisplayName}>{element.displayName}</p>
          </div>
        </div>
      )}
    </span>
  )
}
