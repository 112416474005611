import { MenuItem } from '@headlessui/react'
import React, { type PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

interface ActionMenuItemProps extends PropsWithChildren {
  onClick?: () => void
  className?: string
}

export default function ActionsMenuItem(props: ActionMenuItemProps) {
  return (
    <MenuItem
      className={twMerge(
        'flex w-full cursor-pointer items-center gap-1.5 whitespace-nowrap px-5 py-2 text-start text-sm text-darkblue data-[focus]:text-darkblue/70',
        props.className,
      )}
      as="button"
      onClick={props.onClick}
    >
      {props.children}
    </MenuItem>
  )
}
