import React from 'react'
import { Descendant } from 'slate'
import { CommentsTypeEnum } from 'modules/comments/types'
import * as api from '../../api/commentsApi'
import { useCommentsContext } from '../CommentsList'
import { CommentEditor } from '../comment-editor'
import { CommentInterface } from '../comment/comment.types'
import { MessageBoxProps } from './MessageBox.types'

const updateResponsesCount = (commentList: CommentInterface[], parentId: number) =>
  commentList.map(comment =>
    comment.id === parentId ? { ...comment, totalReplies: comment.totalReplies + 1 } : comment,
  )

export const MessageBox = ({
  parentId,
  setActiveId = () => {},
  setResponses = () => {},
  setOpenedResponses = () => {},
  pageId,
  onError,
  path,
  level = 0,
  replyInfo,
}: MessageBoxProps) => {
  const { commentsType, setComments, commentsList } = useCommentsContext()
  const parentIRI = parentId ? `/api/comment/comments/${parentId}` : null

  async function handleAddComment(textHtml: string) {
    try {
      const newComment = {
        text: textHtml,
        parent: parentIRI,
        ...(commentsType === CommentsTypeEnum.Page
          ? {
              commentSourcePage: {
                pageId: pageId,
              },
            }
          : {
              commentSourceCommunityPost: {
                communityPostId: pageId,
              },
            }),
      }
      const data = await api.addComment(newComment)
      if (parentId === null) {
        setComments([data.data, ...commentsList])
      } else {
        const updatedCommentList = updateResponsesCount(commentsList, parentId)
        setComments(updatedCommentList)
        if (level === 0) {
          setOpenedResponses(true)
        }
        setResponses(prev => [data.data, ...prev])
      }
      setActiveId(0)
    } catch (e) {
      onError && onError(e)
    }
  }

  const initialValue: Descendant[] | undefined = replyInfo
    ? [
        {
          type: 'paragraph',
          children: [
            {
              type: 'mention',
              displayName: replyInfo.displayName,
              id: replyInfo.userId,
              profileImageUrl: replyInfo.profileImage,
              children: [{ text: '' }],
            },
            {
              text: ', ',
            },
          ],
        },
      ]
    : undefined

  return (
    <CommentEditor
      handleAddComment={handleAddComment}
      setActiveId={setActiveId}
      isRoot={!!parentId}
      path={path}
      pageId={pageId}
      initialValue={initialValue}
    />
  )
}
