import React from 'react'
import { CommentsTypeEnum } from '../types'

export const saveIconRenderer = (className: string, handleClick: () => void) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    className={className}
    onClick={handleClick}
    fill="rgba(137, 149, 176, 0.7)"
  >
    <path d="M433.1 129.1l-83.9-83.9C342.3 38.32 327.1 32 316.1 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V163.9C448 152.9 441.7 137.7 433.1 129.1zM224 416c-35.34 0-64-28.66-64-64s28.66-64 64-64s64 28.66 64 64S259.3 416 224 416zM320 208C320 216.8 312.8 224 304 224h-224C71.16 224 64 216.8 64 208v-96C64 103.2 71.16 96 80 96h224C312.8 96 320 103.2 320 112V208z" />
  </svg>
)

export const closeIconRenderer = (className: string, handleClick: () => void) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 320 512"
    fill="rgba(137, 149, 176, 0.7)"
    className={className}
    onClick={handleClick}
  >
    <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
  </svg>
)

export const commentSourceKeyMap: Record<CommentsTypeEnum, string> = {
  [CommentsTypeEnum.Page]: 'commentSourcePage.pageId',
  [CommentsTypeEnum.Community]: 'commentSourceCommunityPost.communityPostId',
}

export const toolbarGroups = [
  {
    id: 1,
    format: 'link',
    type: 'link',
  },
  {
    id: 2,
    format: 'mention',
    type: 'mention',
  },
]
