import React, { useCallback, useEffect, useRef, useState } from 'react'
import { isSafari } from 'shared/utils/is-safari'
import styles from './ListBox.module.css'

interface Option {
  value: string
  label: React.ReactNode
}

interface Props {
  options: Option[]
  onChange: (value: number) => void
}

const useDropdownKeyboardNavigation = ({
  optionsLength,
  activeIndex,
  setActiveIndex,
  selectOption,
  options,
  listRef,
}: {
  optionsLength: number
  activeIndex: number
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>
  selectOption: (value: any) => void
  options: Option[]
  listRef: React.RefObject<HTMLUListElement>
}) => {
  const keyDownCallback = useCallback(
    (e: KeyboardEvent) => {
      switch (e.key) {
        case 'Up':
        case 'ArrowUp':
          e.preventDefault()
          setActiveIndex(prevIndex => {
            const newIndex = prevIndex > 0 ? prevIndex - 1 : optionsLength - 1
            scrollToItem(newIndex, listRef)
            return newIndex
          })
          return
        case 'Down':
        case 'ArrowDown':
          e.preventDefault()
          setActiveIndex(prevIndex => {
            const newIndex = prevIndex < optionsLength - 1 ? prevIndex + 1 : 0
            scrollToItem(newIndex, listRef)
            return newIndex
          })
          return
        case 'Enter':
        case ' ': // Space
          e.preventDefault()
          selectOption(options[activeIndex].value)
          return
        case 'Esc':
        case 'Escape':
          e.preventDefault()
          selectOption(false)
          return
        case 'PageUp':
        case 'Home':
          e.preventDefault()
          setActiveIndex(0)
          scrollToItem(0, listRef)
          return
        case 'PageDown':
        case 'End':
          e.preventDefault()
          setActiveIndex(optionsLength - 1)
          scrollToItem(optionsLength - 1, listRef)
          return
      }
    },
    [activeIndex, optionsLength, setActiveIndex, selectOption, options, listRef],
  )

  useEffect(() => {
    document.addEventListener('keydown', keyDownCallback)
    return () => document.removeEventListener('keydown', keyDownCallback)
  }, [keyDownCallback])
}

const THREE_NEXT_ELEMENTS_HEIGHT = 80

const scrollToItem = (index: number, listRef: React.RefObject<HTMLUListElement>) => {
  const list = listRef.current
  if (list) {
    const item = list.children[index] as HTMLElement
    const itemTop = item.offsetTop
    const itemBottom = itemTop + item.clientHeight
    const listHeight = list.clientHeight

    if (itemTop < list.scrollTop) {
      list.scrollTop = itemTop - THREE_NEXT_ELEMENTS_HEIGHT
    } else if (itemBottom > list.scrollTop + listHeight) {
      list.scrollTop = itemBottom + THREE_NEXT_ELEMENTS_HEIGHT - listHeight
    }
  }
}

const useOpenDropdownHandlers = ({
  setIsDropdownOpen,
}: {
  setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (['ArrowUp', 'ArrowDown', 'Enter', ' '].includes(e.key)) {
        e.preventDefault()
        setIsDropdownOpen(true)
      }
    },
    [setIsDropdownOpen],
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])
}

const useAccessibleDropdown = ({
  options,
  onChange,
}: {
  options: Option[]
  onChange: (value: number) => void
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const listRef = useRef<HTMLUListElement>(null)

  const selectOption = useCallback(
    (value: number) => {
      if (value) {
        onChange(value)
      }
      setIsDropdownOpen(false)
    },
    [onChange],
  )

  useDropdownKeyboardNavigation({
    optionsLength: options.length,
    activeIndex,
    setActiveIndex,
    selectOption,
    options,
    listRef,
  })

  useOpenDropdownHandlers({ setIsDropdownOpen })

  useEffect(() => {
    if (isDropdownOpen && listRef.current && isSafari()) {
      requestAnimationFrame(() => listRef.current?.focus())
    } else if (listRef.current && isSafari()) {
      requestAnimationFrame(() => (listRef.current?.previousSibling as HTMLElement)?.focus())
    }
  }, [isDropdownOpen])

  return {
    isDropdownOpen,
    setIsDropdownOpen,
    activeIndex,
    setActiveIndex,
    selectOption,
    listRef,
  }
}

export const ListBox: React.FC<Props> = ({ options, onChange }) => {
  const { setIsDropdownOpen, activeIndex, setActiveIndex, selectOption, listRef } =
    useAccessibleDropdown({
      options,
      onChange,
    })

  useEffect(() => {
    setIsDropdownOpen(true)
  }, [setIsDropdownOpen])

  return (
    <>
      <ul ref={listRef} className={styles.SelectDropdown} role="listbox">
        {options.map(({ label: Label, value: optionValue }, index) => (
          <li
            key={optionValue}
            id={`select_option_${optionValue}`}
            role="option"
            aria-selected={index === activeIndex}
            onMouseOver={() => setActiveIndex(index)}
            onClick={() => selectOption(Number(optionValue))}
          >
            <label>
              <input
                type="radio"
                name="select_radio"
                value={optionValue}
                onChange={() => selectOption(index)}
              />
              <span>{Label}</span>
            </label>
          </li>
        ))}
      </ul>
    </>
  )
}
