import React from 'react'
import { RenderElementProps } from 'slate-react'
import { CustomElement } from 'modules/community/types/CustomEditor'
import Link, { CustomRenderLinkProps } from './Link/Link'
import { Mention } from './Mention/Mention'

export interface CustomRenderElementProps extends RenderElementProps {
  element: CustomElement
}

export const Element = (props: CustomRenderElementProps) => {
  const { attributes, children, element } = props

  switch (element.type) {
    case 'link':
      return <Link {...(props as CustomRenderLinkProps)} />
    case 'mention':
      return (
        <Mention attributes={attributes} element={element}>
          {children}
        </Mention>
      )
    default:
      return (
        <p className="element-paragraph relative" {...attributes}>
          {children}
        </p>
      )
  }
}
