import React from 'react'

function LikeIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.05436 7.92627H3.8877C4.53203 7.92627 5.05436 8.4486 5.05436 9.09294V17.0096C5.05436 17.6539 4.53203 18.1763 3.8877 18.1763H3.05436C2.41003 18.1763 1.8877 17.6539 1.8877 17.0096V9.09294C1.8877 8.4486 2.41003 7.92627 3.05436 7.92627Z"
        stroke="currentColor"
      />
      <path
        d="M13.1958 3.81942L13.1958 3.81943L11.7529 7.66738L11.4995 8.34294H12.221H16.945C17.1132 8.34294 17.2794 8.37931 17.4323 8.44956C17.5851 8.51981 17.7209 8.62228 17.8305 8.74995C17.94 8.87761 18.0206 9.02745 18.0668 9.18919C18.113 9.35093 18.1237 9.52075 18.0981 9.687L17.053 16.4804C16.9803 16.9526 16.7411 17.3831 16.3785 17.6942C16.0159 18.0053 15.554 18.1763 15.0762 18.1763H8.05436C7.74494 18.1763 7.4482 18.0534 7.2294 17.8346C7.01061 17.6158 6.8877 17.319 6.8877 17.0096V8.55993C6.88769 8.18509 6.99303 7.81779 7.1917 7.49993L10.3655 2.42179L9.95364 2.16436L10.3655 2.42178C10.5081 2.19365 10.7064 2.00553 10.9417 1.87511C11.177 1.74469 11.4416 1.67627 11.7106 1.67627C11.9669 1.67627 12.2194 1.73838 12.4464 1.85728C12.6735 1.97618 12.8684 2.14833 13.0143 2.35899C13.1603 2.56965 13.2531 2.81255 13.2847 3.06689C13.3163 3.32123 13.2858 3.57944 13.1958 3.81942Z"
        stroke="currentColor"
      />
    </svg>
  )
}

export default LikeIcon
