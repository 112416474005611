import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { MentionInterface } from '../comment/comment.types'
import { Mention } from './elements/Mention/Mention'

const parseTextWithMentions = (text: string, mentions: MentionInterface[]): string => {
  const parts = []
  let lastIndex = 0

  mentions?.forEach(({ userId, displayName, profileImage, positionStart, positionEnd }) => {
    if (positionStart > lastIndex) {
      parts.push(text.substring(lastIndex, positionStart))
    }

    const dataAvatarUrl = profileImage ? `data-avatar-url="${profileImage}"` : ''
    const dataId = userId ? `data-id="${userId}"` : ''

    parts.push(
      `<span data-type="mention" contenteditable="false" ${dataAvatarUrl} data-cy="mention-${displayName}" ${dataId}>@${displayName}</span>`,
    )

    lastIndex = positionEnd
  })

  if (lastIndex < text.length) {
    parts.push(text.substring(lastIndex))
  }

  return parts.join('')
}

interface TextWithMentionsProps extends React.HTMLProps<HTMLDivElement> {
  text: string
  mentions: MentionInterface[]
}

export const TextWithMentions = ({ text, mentions, ...rest }: TextWithMentionsProps) => {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (containerRef.current) {
      const htmlContent = parseTextWithMentions(text, mentions)
      containerRef.current.innerHTML = htmlContent

      const mentionElements = containerRef.current.querySelectorAll('[data-type="mention"]')
      mentionElements?.forEach(element => {
        const id = element.getAttribute('data-id')
        const displayName = element.getAttribute('data-cy')?.split('-').pop()
        const profileImageUrl = element.getAttribute('data-avatar-url')

        ReactDOM.render(
          <Mention
            element={{
              displayName: displayName || '',
              id: Number(id) || 0,
              profileImageUrl: profileImageUrl || '',
              type: 'mention',
              children: [{ text: '' }],
            }}
          />,
          element,
        )
      })
    }
  }, [text, mentions])

  return <div ref={containerRef} {...rest} />
}
