export type OnErrorType = (e: unknown) => void

export enum CommentsTypeEnum {
  Community = 'community',
  Page = 'page',
}

export enum AuthorRoleEnum {
  Admin = 'admin',
  Owner = 'owner',
  Moderator = 'assistant',
}

export enum EditorMode {
  Add = 'ADD',
  Edit = 'EDIT',
}
