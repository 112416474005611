import { useEffect, useRef, useState } from 'react'

/**
 * Custom hook that returns a debounced version of a value, updating it after the specified delay.
 * It also provides a function to cancel the timeout, if needed.
 *
 * @template T
 * @param {T} value - The input value that needs to be debounced.
 * @param {number} wait - The delay in milliseconds to wait before updating the debounced value.
 * @param {{ leading: boolean }} [options={ leading: false }] - Options object.
 * @param {boolean} [options.leading=false] - If true, the debounced value will update immediately on the leading edge of the timeout.
 *
 * @returns {[T, () => void]} Returns a tuple where the first element is the debounced value, and the second is a function to cancel the debounce timeout.
 *
 * @example
 * const [debouncedValue, cancelDebounce] = useDebouncedValue(searchTerm, 300);
 *
 * useEffect(() => {
 *   // Effect using debounced value
 * }, [debouncedValue]);
 */
export function useDebouncedValue<T = any>(value: T, wait: number, options = { leading: false }) {
  const [_value, setValue] = useState(value)
  const mountedRef = useRef(false)
  const timeoutRef = useRef<number | null>(null)
  const cooldownRef = useRef(false)

  const cancel = () => window.clearTimeout(timeoutRef.current!)

  useEffect(() => {
    if (mountedRef.current) {
      if (!cooldownRef.current && options.leading) {
        cooldownRef.current = true
        setValue(value)
      } else {
        cancel()
        timeoutRef.current = window.setTimeout(() => {
          cooldownRef.current = false
          setValue(value)
        }, wait)
      }
    }
  }, [value, options.leading, wait])

  useEffect(() => {
    mountedRef.current = true
    return cancel
  }, [])

  return [_value, cancel] as const
}
