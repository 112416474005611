export type DateType = number | string | Date
export type DateUnitType = 'minute' | 'hour' | 'day'

export enum DateUnitEnum {
  minute = 60,
  hour = 60 * 60,
  day = 60 * 60 * 24,
}

export const getTimeDifference = (firstDate: DateType, secondDate: DateType, unit: DateUnitType) =>
  (new Date(firstDate).getTime() - new Date(secondDate).getTime()) / 1000 / DateUnitEnum[unit]

export const calculateDaysBetweenDates = (date1: string | Date, date2: string | Date): number => {
  const startDate = new Date(date1)
  const endDate = new Date(date2)

  const differenceInTime = endDate.getTime() - startDate.getTime()

  return Math.ceil(differenceInTime / (1000 * 3600 * 24))
}
