import React from 'react'
import { RefObject, useEffect, useRef } from 'react'
import { Emoji } from 'emoji-mart'

function useOutsideClicker(ref: RefObject<HTMLDivElement>, update: (value: boolean) => void) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && event.target instanceof Element && !ref.current.contains(event.target)) {
        update(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

type EmojiPickerProps = {
  setEmojiPickerVisible: (value: boolean) => void
  onEmojiSelect: (emoji: Emoji) => void
}

const EmojiPicker = (props: EmojiPickerProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const showEmojis = useRef(true)
  useOutsideClicker(ref, props.setEmojiPickerVisible)
  //https://github.com/missive/emoji-mart/issues/575#issuecomment-1164642269

  useEffect(() => {
    async function getEmojiPicker() {
      const data = await (await fetch('https://cdn.jsdelivr.net/npm/@emoji-mart/data')).json()
      if (showEmojis.current) {
        showEmojis.current = false
        import('emoji-mart').then(EmojiMart => {
          //@ts-ignore
          //https://github.com/missive/emoji-mart/issues/576
          new EmojiMart.Picker({ ...props, data, ref })
        })
      }
    }
    getEmojiPicker()
  }, [])

  return (
      <div  ref={ref} />
  )
}

export default EmojiPicker
