import { Float } from '@headlessui-float/react'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { NeutralButton } from 'shared/components/button'
import ButtonLinkWrapper from 'shared/components/button/components/button-link-wrapper'
import { useFloatHover } from 'shared/hooks/use-float-hover'
import useUser, { isAuthorizedUser } from 'shared/hooks/use-user'
import ActivityIcon from 'shared/icons/activity-icon'
import CalendarIcon from 'shared/icons/calendar-icon'
import MailIcon from 'shared/icons/mail-icon'
import { getTimeDifference } from 'shared/utils/date-utils'
import { getContactUrl } from 'shared/utils/url-utils'
import { UserInfoPopoverSkeleton } from 'modules/community/components/user-info-popover/user-info-popover-skeleton'
import useUserInfo from 'modules/community/hooks/use-user-info'
import { timeSince } from 'modules/post/utils/time-utils'
import { dateFormat } from 'modules/profile/utils/date-time-format'

interface UserInfoPopoverProps {
  userId: number
}

export const UserInfoPopover = ({ children, userId }: PropsWithChildren<UserInfoPopoverProps>) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { show, delayClose, delayOpen, close, open } = useFloatHover({ openDelay: 600 })
  const { data: userInfo } = useUserInfo({ userId, shouldFetch: show })

  const isActive = userInfo?.lastActiveAt
    ? getTimeDifference(Date.now(), userInfo.lastActiveAt, 'minute') <= 5
    : false

  return (
    <Popover
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation()
      }}
    >
      <Float
        show={show}
        offset={10}
        portal
        autoPlacement
        arrow
        enter="transition duration-100 ease-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition duration-75 ease-in"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={'flex h-full items-center'}
      >
        <PopoverButton
          onMouseEnter={delayOpen}
          onMouseLeave={delayClose}
          onClick={show ? close : open}
          className="flex h-fit w-fit items-center justify-around gap-2.5 focus:outline-none"
        >
          {children}
        </PopoverButton>
        <PopoverPanel
          onMouseEnter={delayOpen}
          onMouseLeave={delayClose}
          className="flex flex-col rounded-lg bg-white text-sm shadow-lg"
        >
          {!!userInfo ? (
            <div className="gap-15 flex h-full w-full flex-col items-start gap-10 p-[30px] sm:min-h-[250px] sm:w-[500px] sm:flex-row">
              <img
                src={userInfo.profileImageUrl}
                alt={userInfo.displayName}
                className="h-40 w-40 rounded-full"
                width={160}
                height={160}
              />
              <div className="flex w-full flex-grow flex-col gap-3">
                <div>
                  <h3 className="text-3xl">{userInfo.displayName}</h3>
                </div>
                {isActive ? (
                  <div className="inline-flex items-center gap-2">
                    <div className="h-[12px] w-[12px] rounded-full bg-green" />
                    <span>{t('community.user_info.active_now')}</span>
                  </div>
                ) : (
                  !!userInfo.lastActiveAt && (
                    <div className="inline-flex items-center gap-2">
                      <ActivityIcon />
                      <span>
                        {t('community.user_info.last_activity_at', {
                          date: timeSince(new Date(userInfo.lastActiveAt), user?.dashboardLocale),
                        })}
                      </span>
                    </div>
                  )
                )}
                {!!userInfo?.createdAt && user && isAuthorizedUser(user) && (
                  <div className="inline-flex items-center gap-2">
                    <CalendarIcon />
                    <span>
                      {t('members.list.member.joined')}{' '}
                      {dateFormat(userInfo.createdAt, {
                        dateFormat: user?.dateFormat,
                      })}
                    </span>
                  </div>
                )}
                {!!userInfo?.email && (
                  <div className="inline-flex items-center gap-2">
                    <MailIcon />
                    <a href={`mailto:${userInfo.email}`} title={userInfo.email}>
                      {userInfo.email}
                    </a>
                  </div>
                )}
                {userInfo?.contactId && (
                  <ButtonLinkWrapper
                    href={getContactUrl(userInfo.contactId)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <NeutralButton
                      className="w-full"
                      onClick={(e: React.MouseEvent) => {
                        e.stopPropagation()
                      }}
                    >
                      {t('community.user_info.open_contact_page')}
                    </NeutralButton>
                  </ButtonLinkWrapper>
                )}
              </div>
            </div>
          ) : (
            <UserInfoPopoverSkeleton />
          )}
        </PopoverPanel>
      </Float>
    </Popover>
  )
}
