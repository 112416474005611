import React from 'react'

function MentionToolbarIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.9968 0C13.915 0 16.7137 1.15893 18.7772 3.22183C20.8407 5.28473 22 8.08262 22 11C22 14.7208 20.4293 17.1875 17.8738 17.1875C16.1876 17.1875 14.9304 16.1136 14.2703 14.3138C13.4505 15.6654 12.0724 16.5 10.3091 16.5C7.36574 16.5 5.4952 14.1721 5.4952 11C5.4952 7.78663 7.3011 5.5 10.3091 5.5C11.756 5.5 12.9237 6.02938 13.7476 6.9355V6.1875C13.7473 6.0157 13.8113 5.85001 13.9271 5.72305C14.0429 5.59609 14.202 5.51707 14.3732 5.50155C14.5443 5.48602 14.7151 5.53512 14.8518 5.63918C14.9886 5.74323 15.0814 5.8947 15.112 6.06375L15.123 6.1875V11C15.123 14.0566 16.2412 15.8125 17.8738 15.8125C19.5064 15.8125 20.6246 14.0566 20.6246 11C20.624 8.95387 19.9711 6.96117 18.7608 5.31114C17.5505 3.66111 15.8456 2.43961 13.8937 1.82394C11.9419 1.20827 9.84452 1.23047 7.90612 1.88731C5.96772 2.54416 4.28913 3.80146 3.11401 5.47674C1.93889 7.15201 1.32838 9.15808 1.3711 11.2038C1.41381 13.2495 2.10753 15.2283 3.35156 16.8531C4.59558 18.4779 6.32519 19.6641 8.28932 20.2395C10.2535 20.8149 12.3499 20.7496 14.2744 20.053C14.4444 19.9969 14.6296 20.0093 14.7906 20.0876C14.9516 20.1658 15.0757 20.3037 15.1366 20.472C15.1975 20.6403 15.1903 20.8257 15.1167 20.9888C15.043 21.1519 14.9086 21.2799 14.742 21.3455C13.2322 21.8908 11.6213 22.0993 10.0223 21.9562C8.42332 21.8131 6.87504 21.322 5.48606 20.5173C4.09709 19.7126 2.90108 18.6138 1.98195 17.298C1.06281 15.9821 0.442816 14.4812 0.165461 12.9004C-0.111894 11.3197 -0.0398901 9.6974 0.376421 8.14742C0.792732 6.59743 1.54327 5.15728 2.57536 3.92802C3.60746 2.69876 4.89612 1.71017 6.35092 1.0316C7.80573 0.353036 9.39144 0.000933696 10.9968 0ZM10.3091 6.875C8.14147 6.875 6.8706 8.48375 6.8706 11C6.8706 13.4709 8.19924 15.125 10.3091 15.125C12.419 15.125 13.7476 13.4709 13.7476 11C13.7476 8.48375 12.4767 6.875 10.3091 6.875Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default MentionToolbarIcon
