import React, { useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import { ReactEditor, useSlate } from 'slate-react'
import { Emoji } from 'emoji-mart'
import { useTranslation } from 'next-i18next'
import { twMerge } from 'tailwind-merge'
import EmojiOutlineIcon from 'shared/icons/emoji-outline-icon'
import { closeIconRenderer, saveIconRenderer, toolbarGroups } from '../../../utils/constants'
import { ToolbarButton } from '../common/toolbar-button'
import LinkButton from '../elements/Link/LinkButton'
import MentionButton from '../elements/Mention/MentionButton'
import EmojiPicker from '../elements/emoji-picker/emoji-picker'
import { insertLink } from '../utils/link'
import { insertText } from '../utils/serialize'
import styles from './Toolbar.module.css'

export interface ToolbarProps {
  className?: string
  path?: string
}

export const Toolbar = ({ path, className = '' }: ToolbarProps) => {
  const editor = useSlate()
  const inputRef = useRef<HTMLInputElement>(null)
  const [linkIsEditing, setLinkIsEditing] = useState(false)
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false)
  const { t } = useTranslation()
  const [emojiReferenceElement, setEmojiReferenceElement] = useState<HTMLElement | null>(null)
  const [emojiPopperElement, setEmojiPopperElement] = useState<HTMLDivElement | null>(null)
  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    emojiReferenceElement,
    emojiPopperElement,
    {
      placement: 'top',
      strategy: 'fixed',
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['bottom'],
          },
        },
      ],
    },
  )

  const handleSaveLink = () => {
    if (inputRef.current) {
      const url = inputRef.current.value
      url && insertLink(editor, url)
      setLinkIsEditing(false)
    }
  }
  function closeOnEnterKey(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      handleSaveLink()
    }
  }

  const handleMentionClick = () => {
    insertText(editor, '@')
    ReactEditor.focus(editor) // Focus the editor after inserting the mention
  }

  const handleAddEmoji = (emoji: Emoji) => {
    setEmojiPickerVisible(false)
    // @ts-ignore
    insertText(editor, emoji.native as string)
  }

  return (
    <div className={twMerge('flex items-center', className)}>
      {!linkIsEditing && (
        <>
          {toolbarGroups.map(element => {
            switch (element.type) {
              case 'link':
                return <LinkButton key={element.id} setLinkIsEditing={setLinkIsEditing} />
              case 'mention':
                return path && <MentionButton key={element.id} onClick={handleMentionClick} />
              default:
                return <div />
            }
          })}
          <ToolbarButton ref={setEmojiReferenceElement}>
            <EmojiOutlineIcon
              className="cursor-pointer"
              onClick={() => setEmojiPickerVisible(prev => !prev)}
            />
            <div
              ref={setEmojiPopperElement}
              style={{ ...popperStyles.popper, zIndex: 10 }}
              {...popperAttributes.popper}
              // className={commentEditorStyles.CommentEmojiContent}
            >
              {emojiPickerVisible && (
                <EmojiPicker
                  setEmojiPickerVisible={setEmojiPickerVisible}
                  onEmojiSelect={handleAddEmoji}
                />
              )}
            </div>
          </ToolbarButton>
        </>
      )}
      {linkIsEditing && (
        <div style={{ display: 'flex' }}>
          <input
            className={styles.LinkEditingInput}
            placeholder={t('comments.components.comments.link_menu_placeholder')}
            onKeyDown={closeOnEnterKey}
            ref={inputRef}
          />
          {saveIconRenderer(styles.LinkEditingIcon, handleSaveLink)}
          {closeIconRenderer(styles.CloseIcon, () => setLinkIsEditing(false))}
        </div>
      )}
    </div>
  )
}
