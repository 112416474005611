import { LocaleEnum } from 'shared/enums/locale-enum'

export function timeSince(date: Date, locale?: LocaleEnum) {
  const rtf = new Intl.RelativeTimeFormat(locale, {
    localeMatcher: 'best fit', // other values: "lookup"
    numeric: 'auto', // other values: "auto"
    style: 'long', // other values: "short" or "narrow"
  })
  const deltaDays = (date.getTime() - Date.now()) / (1000 * 3600 * 24)

  return rtf.format(Math.round(deltaDays), 'day')
}
