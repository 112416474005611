import React from 'react'

function MailIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.511 3.18301C1.19162 2.97021 1 2.61215 1 2.22863V2.22767C1 1.54967 1.54968 1.00002 2.22773 1.00002H18.197C18.875 1.00002 19.4246 1.54967 19.4246 2.22767V2.22829V2.22863C19.4246 2.61215 19.2331 2.97021 18.9137 3.18301C17.3553 4.22217 12.6594 7.3525 10.8937 8.52985C10.481 8.80499 9.94363 8.80499 9.53095 8.52985C7.76524 7.3525 3.06942 4.22217 1.511 3.18301Z"
        stroke="#98A2B3"
      />
      <path
        d="M1 2.22838V13.2832C1 13.609 1.12928 13.9213 1.35989 14.1516C1.5902 14.3822 1.90251 14.5115 2.2283 14.5115H18.1963C18.5221 14.5115 18.8344 14.3822 19.0647 14.1516C19.2954 13.9213 19.4246 13.609 19.4246 13.2832V2.22838"
        stroke="#98A2B3"
      />
    </svg>
  )
}

export default MailIcon
