import { useRouter } from 'next/router'
import useSWR from 'swr'
import { COMMUNITY_API_PREFIX } from 'shared/api/constants/api-constants'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { ProfileInfo } from 'modules/community/types/member-interface'

export interface UseUserInfoProps {
  userId: number
  shouldFetch?: boolean
}

export default function useUserInfo({ userId, shouldFetch = true }: UseUserInfoProps) {
  const router = useRouter()
  const { communityPath } = router.query

  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.get, ProfileInfo>({
    method: RequestMethodsEnum.get,
    notFoundResponseHandler: () => {},
  })

  const { data, mutate } = useSWR(
    shouldFetch && `${COMMUNITY_API_PREFIX}/${communityPath}/get-profile-info/${userId}`,
    fetcher,
  )
  return { data, mutate }
}
