import React from 'react'
import { RenderElementProps } from 'slate-react'
import { LinkElement } from 'modules/community/types/CustomEditor'

export interface CustomRenderLinkProps extends RenderElementProps {
  element: LinkElement
}

const Link = ({ attributes, element, children }: CustomRenderLinkProps) => {
  return (
    <a {...attributes} href={element.url}>
      {children}
    </a>
  )
}

export default Link
