import React from 'react'
import { RenderLeafProps } from 'slate-react'
import { CustomText } from 'modules/community/types/CustomEditor'

export interface CustomRenderLeafProps extends RenderLeafProps {
  leaf: CustomText
}

export const Leaf = ({ attributes, children, leaf }: CustomRenderLeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }
  if (leaf.italic) {
    children = <em>{children}</em>
  }
  if (leaf.underline) {
    children = <u>{children}</u>
  }
  return (
    // style={{ paddingRight: '0.001em' }} workaround for "Collapsing Margins" issues.
    <span {...attributes}>{children}</span>
  )
}
