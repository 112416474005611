import React, { useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import { useTranslation } from 'next-i18next'
import { getLastLikes } from 'modules/comments/api/commentsApi'
import { Loader } from 'modules/comments/ui/Loader'
import styles from './Likes.module.css'
import { LikeDetailPopoverProps, LikeInterface } from './Likes.types'

const MAX_DISPLAYED_LIKES = 7

export const LikeDetailPopover = ({
  likesCount,
  referenceElement,
  commentId,
  onError,
}: LikeDetailPopoverProps) => {
  const { t } = useTranslation()
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'top',
      strategy: 'fixed',
    },
  )

  const [lastLikes, setLastLikes] = useState<LikeInterface[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    const fetchLastLikes = async () => {
      try {
        setIsLoading(true)
        const response = await getLastLikes(commentId)
        setLastLikes(response)
        setIsLoading(false)
      } catch (e) {
        onError && onError(e)
      }
    }

    fetchLastLikes()
  }, [])

  const overflowLikes = likesCount > MAX_DISPLAYED_LIKES ? likesCount - MAX_DISPLAYED_LIKES : 0

  return (
    <div
      ref={setPopperElement}
      style={popperStyles.popper}
      {...popperAttributes.popper}
      className={styles.Popover}
    >
      <div className={styles.PopoverBody}>
        <div className={styles.PopoverContent}>
          {isLoading ? (
            <div className={styles.LoaderContainer}>
              <Loader small={false} type="light" />
            </div>
          ) : (
            <>
              {lastLikes?.slice(0, MAX_DISPLAYED_LIKES).map(like => (
                <p className={styles.PopoverLikeName} key={like.createdAt + like.displayName}>
                  {like.displayName}
                </p>
              ))}
              {overflowLikes > 0 && (
                <p className={styles.PopoverOverflowLikes}>
                  {t('comments.components.comments.overflow_likes', { count: overflowLikes })}
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
