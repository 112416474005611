import React, { useState } from 'react'
import { useHover } from 'shared/hooks/use-hover'
import { LikeDetailPopover } from './LikeDetailPopover'
import LikeIcon from './LikeIcon'
import styles from './Likes.module.css'
import { LikesProps } from './Likes.types'

export const Likes = ({
  commentId,
  readOnly,
  handleLikeClick,
  isLiked,
  likesCount,
  onError,
}: LikesProps) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null)
  const { hovered, ref: hoverRef } = useHover()

  return (
    <div className={styles.LikeContainer}>
      <button
        className={`${styles.LikeButton} ${isLiked ? styles.active : ''}`}
        disabled={readOnly}
        onClick={!readOnly ? handleLikeClick : () => {}}
      >
        <LikeIcon width={20} height={20} className={styles.LikeIcon} />
      </button>
      {likesCount > 0 && (
        <div ref={hoverRef}>
          <span className={styles.LikeCount} ref={setReferenceElement}>
            {likesCount}
          </span>
          {hovered && (
            <LikeDetailPopover
              commentId={commentId}
              likesCount={likesCount}
              referenceElement={referenceElement}
              onError={onError}
            />
          )}
        </div>
      )}
    </div>
  )
}
