import React from 'react'

function EmojiOutlineIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.1667 19.3333C5.10392 19.3333 1 15.2294 1 10.1667C1 5.10392 5.10392 1 10.1667 1C15.2294 1 19.3333 5.10392 19.3333 10.1667C19.3333 15.2294 15.2294 19.3333 10.1667 19.3333Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2915 12.4583C14.2915 12.4583 12.9165 14.2916 10.1665 14.2916C7.4165 14.2916 6.0415 12.4583 6.0415 12.4583"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.375 7.41667C13.2534 7.41667 13.1369 7.36838 13.0509 7.28242C12.965 7.19647 12.9167 7.07989 12.9167 6.95833C12.9167 6.83678 12.965 6.7202 13.0509 6.63424C13.1369 6.54829 13.2534 6.5 13.375 6.5C13.4966 6.5 13.6131 6.54829 13.6991 6.63424C13.785 6.7202 13.8333 6.83678 13.8333 6.95833C13.8333 7.07989 13.785 7.19647 13.6991 7.28242C13.6131 7.36838 13.4966 7.41667 13.375 7.41667ZM6.95833 7.41667C6.83678 7.41667 6.7202 7.36838 6.63424 7.28242C6.54829 7.19647 6.5 7.07989 6.5 6.95833C6.5 6.83678 6.54829 6.7202 6.63424 6.63424C6.7202 6.54829 6.83678 6.5 6.95833 6.5C7.07989 6.5 7.19647 6.54829 7.28242 6.63424C7.36838 6.7202 7.41667 6.83678 7.41667 6.95833C7.41667 7.07989 7.36838 7.19647 7.28242 7.28242C7.19647 7.36838 7.07989 7.41667 6.95833 7.41667Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default EmojiOutlineIcon
