import { Editor, Range, Element as SlateElement, Transforms } from 'slate'
import { CustomEditor, MentionElement } from 'modules/community/types/CustomEditor'

export const insertMention = (
  editor: Editor,
  mention: { displayName: string; id: number; profileImageUrl: string },
  target: Range | null,
) => {
  if (target) {
    Transforms.select(editor, target)
  }
  const mentionElement: MentionElement = {
    type: 'mention',
    displayName: mention.displayName,
    id: mention.id,
    profileImageUrl: mention.profileImageUrl,
    children: [{ text: '' }],
  }

  Transforms.insertNodes(editor, mentionElement)

  Transforms.move(editor, { unit: 'offset' })

  Transforms.insertNodes(editor, { text: ' ' })
}

export const isMentionActive = (editor: CustomEditor) => {
  // @ts-ignore
  const [mention] = Editor.nodes(editor, {
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'mention',
  })
  return !!mention
}
