import React, { ComponentProps, useCallback, useState } from 'react'
import { useTranslation } from 'next-i18next'
import Confirmation from 'shared/components/confirmation-modal'
import { CommentsTypeEnum, EditorMode } from 'modules/comments/types'
import { UserInfoPopover } from 'modules/community/components/user-info-popover/user-info-popover'
import { deserializeTextHtml } from 'modules/community/hooks/slate-editor-utils'
import * as postsApi from '../../../community/api/posts-api'
import * as api from '../../api/commentsApi'
import loadMoreStyles from '../../ui/CommentLoadMore.module.css'
import { formatDate, getDateLabel } from '../../utils/dateUtils'
import { useCommentsContext } from '../CommentsList'
import { Likes } from '../Likes'
import { MessageBox } from '../MessageBox'
import CommentActions from '../comment-actions'
import { CommentEditor } from '../comment-editor'
import { TextWithMentions } from '../comment-editor/deserealizeMentionToHTML'
import styles from './Comment.module.css'
import { CommentInterface, CommentProps } from './comment.types'

export const Comment = ({
  data,
  level = 0,
  parentId = data.id,
  setFirstLevelReplies,
  activeId,
  setActiveId,
  currentDate,
  pageId,
  handleDeleteReply,
  onError,
  path,
  handleEditReply,
  ...rest
}: CommentProps) => {
  const { t } = useTranslation()
  const { commentsType, setComments, commentsList, readOnly } = useCommentsContext()
  const [openedResponses, setOpenedResponses] = useState(false)
  const [replies, setReplies] = useState<CommentInterface[]>([])
  const [isLoading, setLoading] = useState(false)
  const [isEnd, setIsEnd] = useState(false)
  const [lastCommentId, setLastCommentId] = useState(0)
  const [isEditing, setIsEditing] = useState(false)
  const [isDeleteAndBanUserOpened, setIsDeleteAndBanUserOpened] = useState(false)

  const isCommentOwner = data.canDeleteComment
  const commentDate = new Date(data.updatedAt ?? data.createdAt).getTime()
  const timeDifference = currentDate - commentDate
  const wasEdited = data.updatedAt !== data.createdAt
  const isCommunityComments = commentsType === CommentsTypeEnum.Community

  const loadCommentReplies = async () => {
    if (!replies.length) {
      try {
        setLoading(true)
        const response = await api.getPageCommentsByLastId(
          parentId,
          commentsType,
          pageId,
          lastCommentId,
        )
        const lastPost = response.items.at(-1)
        if (lastPost) {
          setLastCommentId(lastPost.id)
        }
        if (!response.hasMore) {
          setIsEnd(true)
        }
        const repliesFn = setFirstLevelReplies || setReplies
        repliesFn((prevReplies = []) => [...prevReplies, ...response.items])
        setLoading(false)
      } catch (e) {
        setIsEnd(true)
        onError && onError(e)
      } finally {
        setLoading(false)
      }
    }
  }

  async function loadResponses() {
    if (!openedResponses) {
      await loadCommentReplies()
    }
    setOpenedResponses(!openedResponses)
  }

  async function replyAndLoadResponses() {
    if (level === 0 && !replies.length) {
      await loadCommentReplies()
    }
    setActiveId(data.id)
  }

  const handleLoadMoreCommentsClick = () => {
    try {
      loadCommentReplies()
    } catch (e) {
      onError && onError(e)
    }
  }

  async function handleDeleteComment() {
    const confirmation = confirm(t('comments.components.comments.delete_confirmation_label'))
    if (confirmation) {
      try {
        const response = await api.deleteComment(data.id)
        if (response.status === 200) {
          if (handleDeleteReply) {
            handleDeleteReply(data.id)
          } else {
            setComments(commentsList.filter(el => el.id !== data.id))
          }
        }
      } catch (e) {
        onError && onError(e)
      }
    }
  }

  function handleDeleteReplyComment(deletedCommentId: number) {
    setReplies(replies.filter(el => el.id !== deletedCommentId))
    setComments(
      commentsList.map(commentData =>
        commentData.id === data.id
          ? { ...commentData, totalReplies: commentData.totalReplies - 1 }
          : commentData,
      ),
    )
  }

  const handleLikeClick = (comment: CommentInterface) => async () => {
    try {
      const isLiked = comment.isLiked
      const updateFunction = isLiked ? api.unlikeComment : api.likeComment
      const delta = isLiked ? -1 : 1

      await updateFunction(comment.id)

      const updateCommentLikes = (commentData: CommentInterface) => {
        if (commentData.id === comment.id) {
          return {
            ...commentData,
            likesCount: commentData.likesCount + delta,
            isLiked: !isLiked,
          }
        }
        return commentData
      }

      if (replies?.length > 0) {
        setReplies(replies.map(updateCommentLikes))
      }

      setComments(commentsList.map(updateCommentLikes))
    } catch (e) {
      onError && onError(e)
    }
  }

  const handleEditComment = async (value: string) => {
    try {
      const { data: updatedComment } = await api.editComment(data.id, value)

      if (level > 0 && handleEditReply) {
        return handleEditReply(updatedComment)
      }

      return setComments(comments =>
        comments.map(comment => (comment.id === data.id ? { ...updatedComment } : comment)),
      )
    } catch (e) {
      onError?.(e)
    } finally {
      setIsEditing(false)
    }
  }

  const handleEditCommentReply = (updatedReply: CommentInterface) => {
    setReplies(replies =>
      replies.map(reply => (reply.id === updatedReply.id ? { ...updatedReply } : reply)),
    )
  }

  const handleCancelEditing = useCallback(() => setIsEditing(false), [])

  const deletePostAndBanUser = async () => {
    if (!path) return
    await postsApi.banUser(path, data.userId)
    const response = await api.deleteComment(data.id)
    if (response.status === 200) {
      setComments(commentsList.filter(el => el.id !== data.id))
    }
  }

  if (isEditing) {
    return (
      <CommentEditor
        initialValue={
          deserializeTextHtml(data.text, data.mentions) as ComponentProps<
            typeof CommentEditor
          >['initialValue']
        }
        handleAddComment={handleEditComment}
        isRoot={true}
        mode={EditorMode.Edit}
        setActiveId={setActiveId}
        pageId={pageId}
        onCancel={handleCancelEditing}
        path={path}
        editableComment={data}
      />
    )
  }

  return (
    <>
      <div className="flex w-full flex-row items-start gap-2.5">
        <img className="h-10 w-10 rounded-full" title={data.userName} src={data.userAvatarUrl} />
        <div className="grow-1 ml-1 flex w-[calc(100%-40px)] flex-col items-stretch">
          <div className="relative rounded-xl bg-[#F0F3F6] p-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                {isCommunityComments ? (
                  <UserInfoPopover userId={data.userId}>
                    <span
                      className="max-w-[400px] overflow-hidden text-ellipsis whitespace-nowrap text-darkblue"
                      title={data.userName}
                    >
                      {data.userName}
                    </span>
                  </UserInfoPopover>
                ) : (
                  <span
                    className="max-w-[400px] overflow-hidden text-ellipsis whitespace-nowrap text-darkblue"
                    title={data.userName}
                  >
                    {data.userName}
                  </span>
                )}
                <span className="text-sm text-[#98A2B3]">•</span>
                <div className="leading-3 text-[#98A2B3]" title={formatDate(commentDate)}>
                  {getDateLabel(timeDifference, t, wasEdited)}
                </div>
              </div>
            </div>

            <TextWithMentions
              className={styles.CommentText}
              text={data.text}
              mentions={data.mentions}
            />
            {isCommentOwner && (
              <CommentActions
                commentData={data}
                className="absolute right-3.5 top-3 justify-self-end"
                onDelete={handleDeleteComment}
                onDeleteAndBanUser={() => setIsDeleteAndBanUserOpened(true)}
                onEdit={() => setIsEditing(true)}
              />
            )}
          </div>
          <div className="flex translate-y-1 items-center gap-3 text-sm">
            <Likes
              handleLikeClick={
                rest.handleLikeClick ? () => rest?.handleLikeClick?.(data) : handleLikeClick(data)
              }
              isLiked={data.isLiked}
              likesCount={data.likesCount}
              commentId={data.id}
              readOnly={readOnly}
            />
            {!readOnly && (
              <div
                className="cursor-pointer font-medium text-[#98A2B3] hover:text-[#5DC2FEFF]"
                onClick={replyAndLoadResponses}
              >
                {t('comments.components.comments.reply_label')}
              </div>
            )}
            {level === 0 && !!data.totalReplies && (
              <div
                className="mr-2 flex cursor-pointer text-[#8995b0] hover:text-[#5DC2FEFF]"
                onClick={loadResponses}
              >
                {data.totalReplies}{' '}
                {t('comments.components.comments.responses_label', {
                  count: data.totalReplies,
                })}
                <i
                  className={`fal fa-chevron-down ${
                    openedResponses
                      ? 'ml-1.5 -rotate-180 self-center transition-transform duration-300'
                      : 'ml-1.5 transform-none self-center transition-transform duration-300'
                  }`}
                />
              </div>
            )}
          </div>
          {!readOnly && activeId === data.id && (
            <MessageBox
              path={path}
              parentId={parentId}
              setActiveId={setActiveId}
              level={level}
              setResponses={setFirstLevelReplies || setReplies}
              setOpenedResponses={setOpenedResponses}
              pageId={pageId}
              onError={onError}
              replyInfo={{
                profileImage: data.userAvatarUrl,
                displayName: data.userName,
                userId: data.userId,
              }}
            />
          )}
        </div>
      </div>
      {openedResponses && (
        <div className="ml-[50px] flex flex-col gap-5 border-l border-[#DCE0E9FF] pl-2.5">
          {replies.map(comment => (
            <Comment
              key={comment.id}
              handleLikeClick={handleLikeClick(comment)}
              data={comment}
              level={1}
              parentId={parentId}
              setActiveId={setActiveId}
              activeId={activeId}
              currentDate={currentDate}
              pageId={pageId}
              handleDeleteReply={handleDeleteReplyComment}
              onError={onError}
              path={path}
              setFirstLevelReplies={setFirstLevelReplies || setReplies}
              handleEditReply={handleEditCommentReply}
            />
          ))}
          {!isEnd && (
            <div className={loadMoreStyles.CommentLoadMore} onClick={handleLoadMoreCommentsClick}>
              {t('comments.components.comments.load_more_label')}
            </div>
          )}
        </div>
      )}

      {isDeleteAndBanUserOpened && (
        <Confirmation
          onConfirm={deletePostAndBanUser}
          onCloseModal={() => setIsDeleteAndBanUserOpened(false)}
          confirmationText={t('settings.form.delete_comment_and_ban_user_confirmation.label')}
          errorMessageMap={{
            unprocessableContentError: 'community.error.error_user_already_banned',
          }}
        />
      )}
    </>
  )
}
