import axios, { AxiosRequestConfig } from 'axios'
import { InvalidTokenError, invalidTokenErrorMessages } from '../errors/invalid-token-error'
import { UnauthorizedResponse } from '../errors/unauthorized-response'

const httpClient = axios.create()

httpClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      if (error.response.status === 401) {
        if (invalidTokenErrorMessages.includes(error.response.data.message)) {
          throw new InvalidTokenError()
        } else if (error.response.data.location) {
          throw new UnauthorizedResponse(error.response.data.location)
        } else {
          ;(window as any).Rollbar.error('Unauthorized response', error.response.data)
        }
      } else {
        throw error
      }
    }
  },
)

let isJWTRegenerated = false

const regenerateJWT = () => httpClient.post('/api/user/token/regenerate')

export const baseFetcher = (url: string, config?: AxiosRequestConfig): any =>
  httpClient
    .get(url, config)
    .then(res => res.data)
    .catch(async e => {
      if (e instanceof UnauthorizedResponse) {
        window.location.assign(e.location)
      } else if (e instanceof InvalidTokenError) {
        if (isJWTRegenerated) {
          ;(window as any).Rollbar.error('Invalid token error', e)
        } else {
          try {
            // NOTE: Generating a new JWT
            await regenerateJWT()
            isJWTRegenerated = true
            // NOTE: repeat the same request
            return baseFetcher(url, config)
          } catch (error) {
            if (error instanceof UnauthorizedResponse) {
              window.location.assign(error.location)
            }
          }
        }
      }
      throw e
    })

export default httpClient
